import React from "react";
import { Link } from 'react-router-dom';

import Head from './head';

const NoPage = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <Head />
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-gray-700 mb-8">
          Seite nicht gefunden
        </h2>
        <p className="text-gray-600 mb-8">
          Entschuldigung, die von Ihnen gesuchte Seite konnte nicht gefunden werden.
        </p>
        <div className="inline-block bg-blue-900 text-white py-2 px-4 rounded hover:bg-blue-700">
          <Link to="/startseite" className='hover:scale-125 transition-all hover:underline'>
            Startseite
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
