import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import p1 from './assets/images/p1.jpg';
import p2 from './assets/images/p2.jpg';
import p3 from './assets/images/p3.jpg';
import p4 from './assets/images/p4.jpg';
import p5 from './assets/images/p5.jpg';
import p6 from './assets/images/p6.jpg';
import p7 from './assets/images/p7.jpg';
import p8_1 from './assets/images/p8.jpg';
import p9 from './assets/images/p9.jpg';
import p10 from './assets/images/p10.jpg';
import p11 from './assets/images/p11.jpg';
import p12 from './assets/images/p12.jpg';
import p13 from './assets/images/p13.jpg';
import p8_2 from './assets/images/p8.jpg';
import p15 from './assets/images/p15.jpg';
import p16 from './assets/images/p16.jpg';
import p17 from './assets/images/p17.jpg';
import p19 from './assets/images/p19.jpg';
import p20 from './assets/images/p20.jpg';
import p21 from './assets/images/p21.jpg';
import p22 from './assets/images/p22.jpg';
import cat from './assets/images/cat.jpg';
import apple from './assets/images/apple.jpg';
import apple2 from './assets/images/apple2.jpg';
import door from './assets/images/door.jpg';
import flower from './assets/images/flower.jpg';
import heart from './assets/images/heart.jpg';
import rosehip from './assets/images/rosehip.jpg';
import shelf from './assets/images/shelf.jpg';
import sponge from './assets/images/sponge.jpg';
import entrance from './assets/images/entrance.jpg';
import arrow from './assets/images/arrow.webp';
import threelines from './assets/images/3Lines.webp';
import cross from './assets/images/Cross.webp';
import cross2 from './assets/images/Cross2.webp';
import logo from './assets/images/logo.webp';
import fb from './assets/images/facebook_logo.svg';
import insta from './assets/images/instagram_logo.svg';

import b1 from './assets/images/b1.jpg';
import b2 from './assets/images/b2.jpg';
import b3 from './assets/images/b3.jpeg';
import br1 from './assets/images/br1.jpg';
import br2 from './assets/images/br2.jpg';
import g1 from './assets/images/g1.jpg';
import g2 from './assets/images/g2.jpg';
import g3 from './assets/images/g3.jpg';
import g4 from './assets/images/g4.jpg';
import g5 from './assets/images/g5.jpg';
import db1 from './assets/images/db1.jpg';
import db2 from './assets/images/db2.jpg';
import wb1 from './assets/images/wb1.jpg';
import wb2 from './assets/images/wb2.jpg';
import wb3 from './assets/images/wb3.jpg';

import Head from './head';

function Home() {

  const [nav, setNav] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  const tf_start = useRef(null);
  const tf_workshop = useRef(null);
  const tf_process = useRef(null);
  const tf_about = useRef(null);
  const tf_glazes = useRef(null);
  const tf_dates = useRef(null);
  const tf_socialMedia = useRef(null);
  const tf_impressions = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = (targetRef) => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
      if (!nav) {
        handleNav();
      }
    }
  };

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div>
      <Head />
      <img className='fixed w-full h-[4000px] top-[-500px] z-[-10] object-cover' src={p13} alt="" />
      <header>
        <div className=" bg-white w-full h-[450px] md:h-[600px] flex justify-center items-center flex-col pt-[100px]" ref={tf_start}>
          <div className=" w-full h-[400px] flex justify-center items-center flex-row bg-white">
            <div className='w-[20%] h-full flex justify-center items-center overflow-hidden'>
              <img className='absolute right-[40%] md:right-[50%] scale-[28%] z-50' src={logo}></img>
            </div>
            <div className='w-[55%] flex flex-col justify-center z-40 bg-white'>
              <h1 className=' font-normal text-4xl text-center'>Friederike Gräbner Keramik</h1>
              <h2 className=' font-normal text-2xl mt-5 md:mt-2 text-center'>Kleinstserien und Einzelstücke</h2>
            </div>
            <div className='w-[20%] h-full'>
            </div>
            <div id='menu' className={!nav ? ' z-[80] bg-blue-900 text-white flex h-[400px] justify-center items-center fixed top-[-10px] w-full left-[0%] ease-in-out duration-500' : ' text-white fixed left-[100%] transition ease-in-out duration-500'}>
              <button className='h-[300px] flex flex-col justify-between items-center' onClick={handleClick}>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200 font-normal' onClick={() => handleClick(tf_workshop)}>Werkstatt</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200 font-normal' onClick={() => handleClick(tf_process)}>Arbeitsweise</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200 font-normal' onClick={() => handleClick(tf_about)}>Die Töpferin</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200 font-normal' onClick={() => handleClick(tf_glazes)}>Glasuren</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200 font-normal' onClick={() => handleClick(tf_dates)}>Markttermine</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200 font-normal' onClick={() => handleClick(tf_socialMedia)}>Virtuelles Schaufenster</h2>
                <h2 className='hover:scale-105 w-full p-1 text-2xl hover:bg-blue-700 ease-in-out duration-200 font-normal' onClick={() => handleClick(tf_impressions)}>Impressionen</h2>
                <div onClick={handleNav} className=' w-full justify-center mt-5 flex cursor-pointer hover:scale-110 ease-in-out duration-200'>
                  {!nav ? <img src={cross2} className='my-auto w-[40px]' /> : <img src={cross2} className='my-auto w-[40px]' />}
                </div>
              </button>
            </div>
            <div className='z-30 flex justify-evenly flex-col items-center font-body absolute border-[2px] border-gray-600 bg-white top-[380px] h-[350px] w-[60%] md:w-[35%] md:bottom-[25%] mb-[20px]'>
              <p className='font-custom font-bold'>Leise dreht sich</p>
              <p className='font-custom font-bold'>das Rad der Töpferin:</p>
              <p className='font-custom font-bold'>zwei Hände</p>
              <p className='font-custom font-bold'>lesen die Zeichen</p>
              <p className='font-custom font-bold'>der Erde</p>
              <p className='font-custom font-bold'>umkreisen die Zeit</p>
              <p className='font-custom font-bold'>und formen den Raum.</p>
              <p className='text-sm font-custom font-bold'>B. Baum gartner</p>
            </div>
          </div>
          <div className=" w-full h-[200px] flex justify-end items-end flex-row bg-white">
            <div onClick={handleNav} className=' z-[90] flex m-5 cursor-pointer hover:scale-110 ease-in-out duration-200'>
              {!nav ? <img src={threelines} className='my-auto opacity-0 w-[0px]' /> : <img src={threelines} className='my-auto w-[40px]' />}
            </div>
          </div>
        </div>
      </header>
      <div>
        <div className='pointer-events-none z-[80] w-full h-[100px] bottom-10 left-[-2%] fixed flex justify-end items-end cursor-pointer'>
          <div className='pointer-events-auto w-[60px] h-[60px] flex justify-center items-center bg-blue-900 hover:bg-blue-700 rounded-full hover:scale-110 transition ease-in-out duration-200'><img className='w-[50px] h-[50px]' src={arrow} onClick={() => handleClick(tf_start)}></img></div>
        </div>
        <div id='bg' className='h-full w-full flex flex-col items-center'>
          <img className='z-40 w-[90%] md:w-[60%] mt-[350px] border-solid border-[8px] md:border-[16px]' src={p10}></img>
          <div className='w-[100%] md:w-[60%] mt-[200px]  bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_workshop} className='text-2xl text-center mb-10'>Meine Werkstatt</h2>
            <p className='text-center'>Sie empfängt mich in den Feldsteinwänden des 250-jährigen Bauernhauses einen jeden Morgen und beherbergt die Töpferscheiben. Fertige und unfertige Töpfe. Meine Rohstoffe und tausende Ideen. Sehnsüchte und Hoffnungen.</p>
          </div>
          <img className='z-40 w-[90%] md:w-[60%] mt-[50px] border-solid border-[8px] md:border-[16px] border-white' src={p22}></img>

          <div className='w-[100%] md:w-[60%] mt-[200px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_process} className='text-2xl text-center mb-10'>Die Arbeitsweise</h2>
            <p className='text-center mb-5'>Meine Gefäße sind auf der Töpferscheibe gedreht, oft verformt oder mit kleinen Blaudruckstempelmustern versehen. Spuren der Herstellung sollen auf den fertigen Stücken verbleiben, die Oberflächen sind lebendig und teilweise nicht wiederholbar.</p>
            <p className='text-center'>All meine Glasuren sind aus Aschen, verschiedenen Lehmen, Tonerden, Gesteinsmehlen und Farboxiden selbst gefertigt.</p>
          </div>
          <img className='z-40 w-[90%] md:w-[60%]  mt-[50px] border-solid border-[8px] md:border-[16px] border-white' src={sponge}></img>
          <img className='z-40 w-[90%] md:w-[60%]  mt-[50px] border-solid border-[8px] md:border-[16px] border-white' src={shelf}></img>

          <div className='w-[100%] md:w-[60%] mt-[200px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_about} className='text-2xl text-center mb-10'>Die Töpferin</h2>
            <p className='text-center'>Ich, Jahrgang 1970, bin über viele Umwege zum Töpferhandwerk gekommen. Als Mutter 2er wunderbarer Töchter lebe ich mit meinem Mann, Katzen, Hund und Pferd im ländlichen Erzgebirge.</p>
          </div>
          <div className='w-[100%] md:w-[60%] mt-[100px] bg-white p-10 flex flex-col justify-center items-center'>
            <h2 ref={tf_glazes} className='text-2xl text-center mb-10'>Die Glasuren</h2>
            <div className='flex flex-col md:flex-row mt-10'>
              <p className='text-center mr-1 font-bold'>Grün:</p>
              <p className='text-center'>matt bis seidenmatt, kann teilweise ins Orange bis Braun gehen</p>
            </div>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={g1}></img>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={g2}></img>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={g3}></img>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={g4}></img>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[100px]' src={g5}></img>
            <div className='flex flex-col md:flex-row mt-10 mb-5'>
              <p className='text-center mr-1 font-bold'>Blau:</p>
              <p className='text-center'>seidenmatt, kann teilweise Braun changieren</p>
            </div>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={b1}></img>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={b2}></img>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[100px]' src={b3}></img>
            <div className='flex flex-col md:flex-row mt-10 mb-5'>
              <p className='text-center mr-1 font-bold'>Weißbeige:</p>
              <p className='text-center'>seidenmatt bis glänzend</p>
            </div>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={wb1}></img>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={wb2}></img>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[100px]' src={wb3}></img>
            <div className='flex flex-col md:flex-row mt-10 mb-5'>
              <p className='text-center mr-1 font-bold'>Dunkelblau:</p>
              <p className='text-center'>ein tiefes seidenmattes tiefdunkles Blau</p>
            </div>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={db1}></img>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[100px] ' src={db2}></img>
            <div className='flex flex-col md:flex-row mt-10 mb-5'>
              <p className='text-center mr-1 font-bold'>Braun:</p>
              <p className='text-center'>eine seidenmatte Lehmglasur</p>
            </div>
            <img className='z-40 w-[90%] md:w-[60%] mt-[20px] mb-[50px]' src={br1}></img>
          </div>
          <div className='w-[100%] md:w-[60%] mt-[100px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_dates} className='text-2xl text-center mb-10'>Meine Markttermine</h2>
            <p className='text-center mb-10'>25./26. Mai Töpfermarkt in Gera</p>
            <p className='text-center mb-10'>1./2. Juni Töpfermarkt auf Schloss Köthen</p>
            <p className='text-center mb-10'>29./30. Juni Töpfermarkt Wasserschloss Klaffenbachu</p>
            <p className='text-center mb-10'>26. bis 28. Juli Keramikmarkt in Bad Schandau</p>
            <p className='text-center mb-10'>17./18. August Töpfermarkt in Römhild</p>
            <p className='text-center mb-10'>14./15. September Töpfermarkt in Templin</p>
            <p className='text-center mb-10'>5./6. Oktober Kunsthandwerkermarkt in Naumburg</p>
            <p className='text-center mb-10'>26./27. Oktober Töpfermarkt in Plauen</p>
          </div>
          <div className='w-[100%] md:w-[60%] mt-[100px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_socialMedia} className='text-2xl text-center mb-20'>Mein virtuelles Schaufenster</h2>
            <div className='w-[100%] flex justify-center items-center'>
              <div className='w-[40%] flex justify-center items-center'>
                <a href="https://www.facebook.com/people/Friederike-Gr%C3%A4bner-Keramik/100063538120427/" target='blank' className='flex justify-center'><img className='w-[40%] h-[40%] hover:scale-105 transition duration-200 ease-in-out' src={fb}></img></a>
              </div>
              <div className='w-[40%] flex justify-center items-center'>
                <a href="https://www.instagram.com/friederikegraebnerkeramik/" target='_blank' className='flex justify-center'><img className='w-[40%] h-[40%] hover:scale-105 transition duration-200 ease-in-out' src={insta}></img></a>
              </div>
            </div>
          </div>

          <div className='w-[100%] md:w-[60%] mt-[100px] bg-white p-10 flex flex-col justify-center'>
            <h2 ref={tf_impressions} className='text-3xl text-center'>Impressionen</h2>
          </div>
          <img className='z-40 w-[90%] md:w-[60%] mt-[50px] border-solid border-[8px] md:border-[16px]' src={apple2}></img>
          <img className='z-40 w-[90%] md:w-[60%] mt-[50px] border-solid border-[8px] md:border-[16px]' src={cat}></img>
          <img className='z-40 w-[90%] md:w-[60%] mt-[50px] border-solid border-[8px] md:border-[16px]' src={rosehip}></img>
          <img className='z-40 w-[90%] md:w-[60%] mt-[50px] border-solid border-[8px] md:border-[16px]' src={entrance}></img>
          <img className='z-40 w-[90%] md:w-[60%] mt-[50px] mb-[50px] border-solid border-[8px] md:border-[16px]' src={heart}></img>
        </div>
        <div className='h-[50px] md:h-[100px] w-full bg-blue-900 text-white flex justify-evenly md:text-3xl'>
          <div className='w-[25%] h-full flex justify-center items-center'>
            <Link to="/impressum" className='text-xl hover:scale-125 transition-all hover:underline'>
              Impressum
            </Link>
          </div>
          <div className='w-[25%] h-full flex justify-center items-center'>
            <Link to="/datenschutzerklärung" className='text-xl hover:scale-125 transition-all hover:underline'>
              Datenschutz
            </Link>
          </div>
        </div>
      </div>
      <footer>
      </footer>
    </div>
  );
}

export default Home;
