import React from 'react';
import { Helmet } from 'react-helmet';

const Head = () => {
  return (
    <div>
      <Helmet>
        <title>Gräbner Keramik</title>
        <meta name="description" content="Kleinstserien und Einzelstücke" />
        <meta name="keywords" content="Keramik, Werkstatt, Handgefertigt" />
        <meta name="author" content="Friederike Gräbner" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Gräbner Keramik" />
        <meta property="og:description" content="Kleinstserien und Einzelstücke" />
        <meta property="og:image" content="https://graebnerkeramik.de//images/thumbnail.webp" />
        <meta property="og:url" content="https://graebnerkeramik.de/" />
        <meta property="og:type" content="website" />
      </Helmet>
    </div>
  );
};

export default Head;